import {DateTime} from 'luxon'

/**
 * Available trade types
 * @type {({label: string, value: number}|{label: string, value: number}|{label: string, value: number}|{label: string, value: number}|{label: string, value: number})[]}
 */
export const tradeTypes = [
    { value: 0, label: 'Buy' },
    { value: 1, label: 'Sell' },
    { value: 2, label: 'Buy limit' },
    { value: 3, label: 'Sell limit' },
    { value: 4, label: 'Buy stop' },
    { value: 5, label: 'Sell stop' },
    { value: 6, label: 'Balance' },
]

/**
 * Convert trade type to type name
 * @param type
 * @returns {string}
 */
export function tradeTypeToName(type) {
    switch(type) {
        case 0:
            return 'Buy'
        case 1:
            return 'Sell'
        case 2:
            return 'Buy limit'
        case 3:
            return 'Sell limit'
        case 4:
            return 'Buy stop'
        case 5:
            return 'Sell stop'
        case 6:
            return 'Balance'
        default:
            return String(type)
    }
}

/**
 * Convert trade magic number to expert name
 *
 * @param magic
 * @returns {string|*}
 */
export function tradeMagicToName(magic) {
    switch(magic) {
        case 5:
            return 'Martin (5)';
        case 6:
            return 'Martin (6)';
        case 7:
            return 'Martin (7)';
        case 8:
            return 'Fishing';
        case 9:
            return 'PL';
        default:
            return magic;
    }
}

/**
 * Date time to string
 *
 * @param dateTimeString
 * @returns {string}
 */
export function dateTimeToString(dateTimeString) {
    if(typeof dateTimeString !== 'string' || dateTimeString.length === 0) {
        return ''
    }
    // return DateTime.fromISO(dateTimeString).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
    return DateTime.fromISO(dateTimeString).toUTC().toFormat("yyyy'-'LL'-'dd', 'HH':'mm':'ss")

}

/**
 * Date time to string
 *
 * @param dateTimeString
 * @returns {string}
 */
export function dateTimeToDateString(dateTimeString) {
    if(typeof dateTimeString !== 'string' || dateTimeString.length === 0) {
        return ''
    }
    // return DateTime.fromISO(dateTimeString).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
    return DateTime.fromISO(dateTimeString).toUTC().toFormat("yyyy'-'LL'-'dd'")

}

/**
 * Generate random string with desired length
 *
 * @param length
 * @returns {string}
 */
export function generateRandomString(length) {
    const alphabet = 'abcdefghijklmnopqrstuwxyzABCDEFGHIJKLMNOPQRSTUWXYZ0123456789'
    const alphabetLength = alphabet.length
    let result = ''
    for (let i = 0; i < length; i++) {
        result += alphabet.charAt(Math.floor(Math.random() * alphabetLength))
    }
    return result
}
